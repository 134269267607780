.bg-white {
  background: #fff;
}

.operator {
  max-width: 40rem;
  margin: 0 auto;
  text-align: left;
  // display: block !important;
  --ion-grid-padding: 0;
  --ion-grid-column-padding: 0;
  
  .operator-content {
    --background: #eeeeee;
  }

  .operator-header {
    background-color: #fe6600;
    color: #fff;
    padding: 30px 20px;
    font-size: 18px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 6;
  }

  .operator-filter {
    padding-bottom: 65px;
  }
  
  .operator-filter-item {
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 14px;

    .filter-item-title {
      .rightsmall {
        float: right;
        padding: 5px;
      }
    }

  }

  .filter-date-label {
    font-size: 10px;
    color: #9e9e9e;
    margin: 15px 0;
  }
  
  .filter-date {
    padding: 0;
    padding-bottom: 8px;
    border-bottom: 1px solid #e0e0e0;
  }

  .operator-chip-container {
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
    margin-top: 16px;

    &::-webkit-scrollbar {
      display: none;
    }
    
    .operator-chip {
      border: 1px solid #9e9e9e;
      padding: 10px 12px;
      border-radius: 17px;
      color: #595959;
      font-size: 12px;
      display: inline-block;
      margin-right: 8px;
      background-color: #fff;
      cursor: pointer;
  
      &.selected {
        border-color: #fe6600;
        background-color: #fef1e6;
        color: #fe6600;
      }

      &.disabled {
        opacity: 0.5;
      }
    }
  }

  .operator-footer {
    position: fixed;
    width: 100%;
    text-align: center;
    bottom: 0;
    padding: 10px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    z-index: 99;
    height: 60px;

  }
  
  .btn-filter {
    --background: #fe6600;
    --background-hover: #fe6600;
    --box-shadow: none;
  }

  .operator-filter-search {
    --icon-color: #bdbdbd;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 20px 15px;

    .searchbar-input {
      --placeholder-color: #bdbdbd;
      --placeholder-opacity: 1;
      --background: #fff;
      box-shadow: none;
      border: 1px solid #bdbdbd;
      border-radius: 6px;
    }
  }

  .operator-searchbar {
    --icon-color: #bdbdbd;
    padding: 0;
    margin-right: 15px;

    .searchbar-input {
      --placeholder-color: #bdbdbd;
      --placeholder-opacity: 1;
      --background: #fff;
      box-shadow: none;
      border: 1px solid #bdbdbd;
      border-radius: 6px;
      font-size: 12px;
      padding: 6px 10px 6px 40px;
    }

    .searchbar-search-icon {
      left: 13px;
      top: 13px;
      width: 19px;
      height: 19px;
    }
  }

  a {
    text-decoration: none;
    color: #404040;
  }
}

.btn-create {
  line-height: 46px;
  background-color: #fe6600;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}