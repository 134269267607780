
span {

  &.status {
    display: inline;
    font-weight: 500;

    &.rejected {
      color: #F23D3D;
    }

    &.approved {
      color: #28A94A;
    }
  }

  &.waiting-approval {
    display: block; 
    margin-bottom: 8px;
  }

  &.timestamp {
    display: block;
    color: #404040;
    line-height: 1;
    font-size: 12px;
  }

  &.is-bottom {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
