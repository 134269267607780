.order-header {
  float: right;

  img {
    margin-left: 20px;
    cursor: pointer;
  }
}

.order-content {
  padding: 20px;
  padding-bottom: 0;

  .order-item {
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    padding: 15px;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 12px;

    .item-header {
      padding-bottom: 14px;
      margin-bottom: 14px;
      border-bottom: 1px solid #e0e0e0;
      
      img {
        vertical-align: middle;
        float: right;
        cursor: pointer;
      }

      span {
        vertical-align: middle;
        line-height: 1.5;
      }

      .accepted {
        color: #21bf73;
      }
  
      .onbid {
        color: #e0ad27;
      }
  
      .nofleet {
        color: #f23d3d;
      }
    }

    .item-content {
      padding-bottom: 14px;
      margin-bottom: 14px;
      border-bottom: 1px solid #e0e0e0;

      .trip-dot {
        width: 8px;
        height: 8px;
        margin-right: 10px;
        display: inline-block;
        border: 1px solid #fe6600;
        border-radius: 50%;
      }
  
      .destination {
        margin-bottom: 15px;
      }
  
      .origin {
        margin-bottom: 20px;
  
        .trip-dot::after {
          content: "";
          display: block;
          position: relative;
          top: 10px;
          left: 2px;
          border-left: 1px solid #dddddd;
          height: 21px;
        }
      }
  
      .trip-place-name {
        display: inline-block; 
        vertical-align: top;
        width: 90%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .item-title {
      color: #9e9e9e;
      font-size: 10px;
      margin-bottom: 5px;
    }
  }
}

.float-button-container {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  text-align: center;
  
  .float-button {
    background-color: #fff;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    padding: 8px 20px;
    border-radius: 24px;
    outline: 0 !important;
  }
}