
.shipper-menu-button {
  button {
    padding: none;
    margin: none;
    width: 5px;
  }
}

.search-box input {
  border: 1px solid #bdbdbd !important;
  border-radius: 30px !important;
}