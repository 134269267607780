.login {
  //background-image: url('../../../assets/group_62.svg');
  background-position: center bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 100vh;
  max-width: 40rem;
  margin: 0 auto;
}
.logo-login {
  width: 60%;
  height: 60px;
}

.login-page {
  display: flex;
  // justify-content: center;
  flex-direction: column;
  padding: 40px 20px 20px;
  height: 100%;

  .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 43px;
  }

  label {
    color: #9e9e9e;
    font-size: 14px;
  }

  input {
    font-size: 14px;
    width: 100%;
    border: none;
    border-bottom: 1px solid #e0e0e0;
    color: #0d0d0d;
    padding: 0;
    padding-bottom: 8px;
    margin-bottom: 30px;
    margin-top: 10px;
    font-weight: bold;
  }

  .button {
    margin-top: 30px;
    min-height: 50px;
    width: 100%;
    --box-shadow: none;
    --ion-color-base: #fe6600 !important;
    --ion-color-tint: #fe6600 !important;
    --ion-color-shade: #fe6600 !important;
  }

  input:focus {
    outline: none !important;
  }
}
