
.shipper-menu-button {
  button {
    padding: none;
    margin: none;
    width: 5px;
  }
}

.text-muted {
  color: lightgrey;
}

.error-information {
  text-align: center;
}

code {
  font-size: 8pt;
}

.sc-ion-alert-md{
  white-space: normal !important;
}

.ritase-tabs {
  box-shadow: none !important;
  border-bottom: 1px solid #ddd;
  top: 56px !important;
  .Mui-selected {
    background: white;
  }
  .ritase-tab {
    font-weight: bold;
    font-size: 12pt;
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: capitalize;
  }
}

.distributor.order-status-container {
  width: auto;
  margin: auto;

  .MuiBackdrop-root {
    width: auto;
    margin: auto;
  }

  .MuiDrawer-paperAnchorRight {
    width: 50%;

    @media screen and (max-width: 320px) {
      position: fixed;
      width: 80%;
    }
  }

  .order-status-item {
    .order-status-value {
      font-weight: 500;
      margin-bottom: 3px;
      font-size: 14px;
    }

    .order-status-date {
      color: #9e9e9e;
      font-size: 12px;
      text-align: right;
      margin-left: auto;
    }

    .order-status-description {
      text-transform: none;
      font-size: 12px;
      font-style: italic;
    }

    img {
      z-index: 3000;
    }

    &:not(:last-child)::before {
      content: "";
      display: block;
      position: relative;
      top: 37px;
      left: 20px;
      border-left: 1px solid #9e9e9e;
      height: 50px;
    }

    &:not(:last-child).pass::before {
      border-color: #fe6600;
    }

    &.inactive {
      color: #9e9e9e !important;
    }
  }
}

.list-location {
  padding-left: 25px;
  list-style: none;

  label {
    color: #9b9b9b !important;
    font-weight: normal !important;
    margin-bottom: 0;
  }
}

.list-location-item {
  padding-bottom: 18px;
  position: relative;

  &::before {
    content: "";
    width: 11px;
    height: 11px;
    border-radius: 100%;
    border: 1px solid #fe6600;
    top: 5px;
    position: absolute;
    left: -20px;
  }

  &:not(:first-child)::before {
    background-color: #fe6600;
  }
  
  &:not(:last-child)::after {
    content: "";
    width: 1px;
    background: #e0e0e0;
    height: 95%;
    position: absolute;
    left: -14px;
    top: 22px;
  }

  .list-location-address {
    margin-left: -17px;
  }
}