ion-content {
  background-color: #f9f9f9;
}

canvas {
  border-bottom: solid 1px #f0f0f0;
  display: block; 
}


.signature-box {
  border-radius: 8px;
  border: dashed 1px #979797;
  background-color: #ffffff;
  margin: 10px auto;
}

.instruction {
  margin-bottom: 39px;
  font-size: 16px;
}

.reset-box {
}

.reset-button {
  --box-shadow: none;
  --background: none;
  --color: #ccc;
}

.swal-update-package {
  background-color: #f3f3f3 !important;
  color: #4a4a4a !important;
}