.order-section {
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .order-section-title {
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
  }

  .order-content {
    padding: 20px;
    background-color: #fff;
    font-size: 14px;

    .order-row {
      margin-bottom: 16px;
      .order-row-title {
        color: #9e9e9e;
      }
    }
  }
}

.order-detail {
  .back-btn {
    position: fixed;
    top: 40px;
    left: 18px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    border-radius: 50%;
    padding-top: 3px;
    padding-left: 8px;
    z-index: 999;

    img {
      width: 6px;
      height: 10px;
    }
  }
}

.modal-close-issue .modal-wrapper {
  height: 60%;
  top: 50%;
  position: absolute;
  display: block;
  border-radius: 25px;
}