.ritase-toolbar {
  --box-shadow: none;
  .logo {
    text-align: left;
  }
}

.homescreen {
  ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;

    .content {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .main {
        display: flex;
        flex-direction: column;
        width: 100%;

        h4,h6 {
          text-align: left;
        }

        .home-image {
          width: 200px;
          margin: 20px auto;
        }

        .activity-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
