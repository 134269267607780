.admin-manage-form {
  font-size: 14px;

  .admin-manage-form-item {
    margin-bottom: 20px;

    .admin-manage-select {
      padding: 10px 0 5px;
      border-bottom: 1px solid #e0e0e0;
    }

    .native-input, .native-textarea {
      // opacity: 1;
      padding: 10px 0 5px;
      border-bottom: 1px solid #e0e0e0;
      --placeholder-color: #bdbdbd;
      --placeholder-opacity: 1;
    }

    .native-input[disabled] {
      opacity: 0.5;
    }

    .native-textarea {
      height: 80px;
    }

    .admin-manage-checkbox {
      --border-color: #fe6600;
      --border-width: 1px;
      --background-checked: #fe6600;
      --size: 16px;
      float: right;
    }

    &.admin-manage-form-date {
      img {
        filter: brightness(0.8);
        position: relative;
        top: 5px;
      }

      .filter-date {
        border: none;
        padding: 10px 10px 0;
        width: 100%;
        outline: 0 !important;
      }
    }

    .admin-manage-image {
      display: flex;
      align-items: center;
      
      .admin-image {
        width: 120px;
        height: 120px;
        margin: 10px 20px 10px 0;
      }

      @media screen and (max-width: 768px) {
        display: block;

        .admin-image {
          display: block;
        }
      }
    }
  }
}