.operator-homescreen {
  .home-header {
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;

    .approval-button {
      float: right;
      cursor: pointer;

      .notification-badge {
        background-color: red;
        position: absolute;
        top: 0;
        right: 0;
        padding: 5px;
        border-radius: 100%;
      }
    }
  }

  .home-section {
    padding: 20px;
  }

  .homecard-container {
    width: 100%;
    overflow-x: scroll;
    padding: 6px 2px;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .home-card {
    padding: 10px 10px 20px;
    border-radius: 8px;
    color: #fff;
    margin-top: 10px;
    margin-right: 10px;
    text-align: left;
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;

    &.all {
      background-color: #27a397;
      background-image: url('../../../../assets/new-icons/bg-green.png');
    }

    &.accepted {
      background-color: #21bf73;
      background-image: url('../../../../assets/new-icons/bg-green2.png');
    }

    &.bid {
      background-color: #fdd365;
      color: #404040;
      background-image: url('../../../../assets/new-icons/bg-yellow.png');
    }

    &.no-available {
      background-color: #f23d3d;
      background-image: url('../../../../assets/new-icons/bg-red.png');
    }

    &.slide {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
      color: #404040;
      // border: 1px solid #f4f4f4;
      width: 130px;
      display: inline-block;
      background-image: url('../../../../assets/new-icons/bg-grey.png');

      &.slide-all {
        background-image: url('../../../../assets/new-icons/bg-green3.png');
        color: #fe6600;
      }

      &.slide-all.issue {
        background-image: url('../../../../assets/new-icons/bg-red3.png');
        color: #f23d3d;
      }

      .home-card-issue {
        font-size: 12px;
        margin-bottom: 8px;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}