.order-header {
  float: right;

  img {
    margin-left: 20px;
    cursor: pointer;
  }
}

.order-content {
  padding: 20px;
  padding-bottom: 0;
}


ion-select {
  max-width:100% !important;
}

.modal-filter-issue {
  .modal-wrapper {
    height: 53%;
    top: 52%;
    position: absolute;
    display: block;
    border-radius: 25px;
  }
}

@media (min-height: 0px) and (max-height: 640px) {
  .modal-filter-issue .modal-wrapper {
    height: 55%;
    top: 50%;
    position: absolute;
    display: block;
    border-radius: 25px;
  }
}



