.trip-detail {
  background-color: #fff;
  padding: 15px 20px;
}

.package-modal .modal-wrapper {
  --height: 80%;
  top: 20%;
  position: absolute;
  display: block;
}

.text-grey {
  color: grey !important;
}

.package-background {
  background: url(../../../../assets/new-icons/box.svg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  height: 40px;
  width: 40px;
  margin-bottom: 7px;
  line-height: 50px;
  font-size: 16px;
  font-weight: 700;
}
