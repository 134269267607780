.order-section {
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .order-section-title {
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
  }

  .order-content {
    padding: 20px;
    background-color: #fff;
    font-size: 14px;

    .order-row {
      margin-bottom: 16px;
      .order-row-title {
        color: #9e9e9e;
      }
    }
  }
}

.order-detail {
  .back-btn {
    position: fixed;
    top: 40px;
    left: 18px;
    background-color: #fff;
    width: 25px;
    height: 25px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    border-radius: 50%;
    padding-top: 3px;
    padding-left: 8px;
    z-index: 999;

    img {
      width: 6px;
      height: 10px;
    }
  }

  .order-detail-card-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 15px 15px;
    z-index: 999;
    
    .order-detail-card {
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0,0,0,0.15);
      border-radius: 8px;
      padding: 15px;

      img {
        margin-right: 12px;
        width: 44px;
      }

      .order-status {
        float: right;
        background-color: #fe6600;
        border-radius: 12px;
        font-size: 10px;
        padding: 5px 12px;
        color: #fff;
      }

      .order-card-title {
        color: #9e9e9e;
        margin-bottom: 4px;
      }
    }
  }

  .status-btn {
    position: relative;
    bottom: 50px;
    float: right;
    background-color: #fff;
    width: 40px;
    height: 40px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    border-radius: 50%;
    line-height: 49px;
    text-align: center;
  }
}

.order-status-container {
  width: 40rem;
  margin: auto;

  .MuiBackdrop-root {
    width: 40rem;
    margin: auto;
  }

  .MuiDrawer-paperAnchorRight {
    position: absolute;
    width: 60%;
    border-radius: 10px 0 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .order-status-title {
    padding: 20px 15px;
    border-bottom: 1px solid #e0e0e0;
    font-weight: 500;
  }
  
  .order-status-list {
    padding: 20px 15px;
    
    .order-status-item {
      display: flex;
      text-transform: capitalize;
      align-items: center;
      
      &:not(:last-child) {
        margin-bottom: 30px;
      }
      
      img {
        margin-right: 10px;
      }

      .order-status-value {
        font-weight: 500;
        margin-bottom: 3px;
        font-size: 14px;
      }

      .order-status-date {
        color: #9e9e9e;
        font-size: 12px;
      }

      &:not(:last-child)::before {
        content: "";
        display: block;
        position: relative;
        top: 34px;
        left: 20px;
        border-left: 1px solid #9e9e9e;
        height: 30px;
      }

      &:not(:last-child).pass::before {
        border-color: #fe6600;
      }
    }
  }

  @media screen and (max-width: 635px) {
    .MuiDrawer-paperAnchorRight {
      position: fixed;
    }
  }

  @media screen and (max-width: 320px) {
    .MuiDrawer-paperAnchorRight {
      position: fixed;
      width: 80%;
    }
  }
}