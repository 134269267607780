.operator-profile {
  .profile-header {
    background-image: url('../../../../assets/new-icons/group_5.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
  }

  .profile-logo {
    color: #fe6600;
    font-size: 21px;
    background-color: #fff;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 50%;
    line-height: 2.2;
    text-transform: uppercase;
    margin-right: 15px;
  }

  .profile-content {
    padding: 20px;

    .profile-btn {
      --background: #ffffff;
      --background-hover: #ffffff;
      --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      --color: #f23d3d;
      --padding-top: 25px;
      --padding-bottom: 25px;
      text-transform: none;
    }
  }
}