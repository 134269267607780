.report-header {
  float: right;

  img {
    margin-left: 20px;
    cursor: pointer;
  }
}

.report-content {
  .report-item {
    background-color: #fff;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    line-height: 1.7;
    color: #404040;
    font-size: 12px;

    img {
      float: right;
      position: relative;
      top: 27px;
    }
  }
}

.operator.operator-report .operator-content {
  --background: #fff;
}

.report-filter.sc-ion-modal-md-h {
  --height: 300px;

  .modal-wrapper.sc-ion-modal-md {
    position: fixed;
    bottom: 0;
    border-radius: 15px 15px 0 0;

    .report-filter-content {
      padding: 15px;

      .report-filter-title {
        font-size: 18px;
        color: #404040;
        font-weight: 700;
        margin-bottom: 5px;
      }

      .radio-container {
        display: block;
        position: relative;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        border-bottom: 1px solid #e0e0e0;
        padding: 15px 0;

        input[type=radio] {
          position: absolute;
          opacity: 0;
          cursor: pointer;

          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: 15px;
          right: 0;
          height: 16px;
          width: 16px;
          background-color: #fff;
          border: 1px solid #fe6600;
          border-radius: 50%;

          &:after {
            top: 2px;
            left: 2px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fe6600;
            content: '';
            color: #fe6600;
            position: relative;
          }
        }

        &:hover input[type=radio]:not(:checked) ~ .checkmark {
          background-color: #d4edea;
        }

        .filter-date {
          border: none;
          padding: 10px 10px 0;
          width: 100%;
          outline: 0 !important;

          &::placeholder {
            color: #bdbdbd;
          }
        }

        img {
          filter: brightness(0.8);
          position: relative;
          top: 5px;
        }
      }

      .btn-filter {
        --box-shadow: none;

        &.ion-color-primary {
          --ion-color-base: #fe6600 !important;
          --ion-color-tint: #fe6600 !important;
          --ion-color-shade: #fe6600 !important;
        }
      }
    }
  }
}