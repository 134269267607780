.package-box {
  position: relative;
  color: #4a4a4a;
  text-align: center;
  padding-top: 15px;
  overflow: hidden;
}

.package-box:after {
    content: '';
    width: 2px;
    height: 15px;
    position: absolute;
    background: #b8b8b8;
    top: 0;
}

.package-box:before {
    content: '';
    width: calc(100% - 18px);
    height: 2px;
    position: absolute;
    background: #b8b8b8;
    top: 0;
    left: 8px;
}

.package-box .package-item {
    min-width: 10px;
    height: 2.5rem;
    text-align: center;
    border: 2px solid #b8b8b8;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
}

.package-box .line {
  height: 15px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.package-box .line:after {
    content: '';
    height: 21px;
    width: 2px;
    background: #b8b8b8;
    position: absolute;
    top: -2px;
    left: 4px;
    transform: rotate(30deg);
}

.package-box .line:before {
    content: '';
    height: 21px;
    width: 2px;
    background: #b8b8b8;
    position: absolute;
    top: -2px;
    right: 4px;
    transform: rotate(-30deg);
}

