.main-card {
  margin-left: 0;
  margin-right: 0;

  &.active {
    border: 1px solid #fe6600;  
  }
}


.item-content {
  .trip-dot {
    width: 8px;
    height: 8px;
    margin-right: 10px;
    display: inline-block;
    border: 1px solid #fe6600;
    border-radius: 50%;
  }

  .destination {
    margin-bottom: 15px;
  }

  .origin {
    margin-bottom: 15px;

    .trip-dot::after {
      content: "";
      display: block;
      position: relative;
      top: 10px;
      left: 2px;
      border-left: 1px solid #dddddd;
      height: 22px;
    }
  }

  .trip-place-name {
    display: inline-block; 
    vertical-align: top;
    width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #4a4a4a;
  }
}
