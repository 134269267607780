.scan-page {
  ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    text-align: center;
  }

  ion-label {
    text-align: center;
  }

  ion-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 90px 1px;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}

.alert-custom {
  p {
    margin: 0px;
    color: #000;
  }
  .text-muted {
    color: #9e9e9e;
  }
}
