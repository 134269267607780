
.timeline {
  margin: 0;
  font-size: 14px;
  list-style: none;

  .timeline-item {
    position: relative;
    padding-bottom: 20px;
  }

  .timeline-item-tail {
    position: absolute;
    left: 4px;
    height: 100%;
    border-left: 2px solid #DFE4ED;
    
    &.last {
      display: none;
    }
  }

  .timeline-item-node-normal{
    left: -1px;
    width: 12px;
    height: 12px;
  }

  .timeline-item-node {
    position: absolute;
    background-color: #DFE4ED;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .timeline-item-wrapper {
    position: relative;
    padding-left: 28px;
    top: -3px;
  }

  span {
    &.assign {
      display: block;
      
      .title {
        display: block;
        font-weight: 600;
        line-height: 1.25rem;
      }
  
      &.timestamp {
        display: block;
        color: #404040;
        line-height: 1;
        font-size: 12px;
      }
  
      &.is-bottom {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }
  }
}