.report-detail-header {
  padding: 15px;
  font-size: 13px;
  line-height: 1.7;
  border-bottom: 1px solid #d9d9d9;

  .report-title {
    color: #9e9e9e;
  }

  .report-data {
    font-weight: 500;
  }
}

.report-detail-list {
  font-size: 13px;

  .report-detail-item {
    line-height: 2.4;
    padding: 15px;
    border-bottom: 1px solid #d9d9d9;

    .report-item-title {
      color: #9e9e9e;
    }
  }

  .report-detail-item:nth-child(even) {
    background-color: #f5f5f5;
  }
}