.create-order {
  .cr-order-section {
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 15px;

    &.cr-order-step {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      align-items: flex-end;

      .cr-order-step-item {
        text-align: right;
        color: #595959;
        font-weight: 600;
        font-size: 13px;

        .img {
          border: 1px solid #d9d9d9;
          border-radius: 50%;
          text-align: center;
          display: inline-block;
          margin-bottom: 5px;

          &.img-location {
            padding: 8px 11px;
          }
  
          &.img-land {
            padding: 11px;
          }
  
          &.img-time {
            padding: 8px 10px;
          }
        }

        &:not(:first-child)::before {
          width: 70px;
          content: '';
          display: inline-block;
          border-bottom: 2px solid #d9d9d9;
          position: relative;
          top: -5px;
        }

        &.active {
          .img {
            background-color: #fe6600;
            border-color: #fe6600;
          }

          &:not(:first-child)::before {
            border-color: #fe6600;
          }
        }
      }
    }

    .cr-order-form {
      font-size: 14px;

      .cr-order-form-item {
        margin-bottom: 20px;

        .cr-order-select {
          padding: 10px 0 5px;
          border-bottom: 1px solid #e0e0e0;
          background: url('../../../../assets/new-icons/downsmall.svg') no-repeat right;
        }

        .native-input, .native-textarea {
          // opacity: 1;
          padding: 10px 0 5px;
          border-bottom: 1px solid #e0e0e0;
          --placeholder-color: #bdbdbd;
          --placeholder-opacity: 1;
        }

        .native-input[disabled] {
          opacity: 0.5;
        }

        .native-textarea {
          height: 80px;
        }

        .cr-order-checkbox {
          --border-color: #fe6600;
          --border-width: 1px;
          --background-checked: #fe6600;
          --size: 16px;
          float: right;
        }

        &.cr-order-form-date {
          img {
            filter: brightness(0.8);
            position: relative;
            top: 5px;
          }

          .filter-date {
            border: none;
            padding: 10px 10px 0;
            width: 100%;
            outline: 0 !important;
          }
        }
      }
    }

    .cr-order-add-package {
      text-align: center;
      padding: 10px;
      border: 1px dashed #fe6600;
      color: #fe6600;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 10px;
        vertical-align: bottom;
      }
    }
  }

  .cr-order-footer {
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    margin-bottom: 0;

    .cr-order-btn {
      --box-shadow: none;
      text-transform: capitalize;

      &.ion-color-primary {
        --ion-color-base: #fe6600 !important;
        --ion-color-tint: #fe6600 !important;
        --ion-color-shade: #fe6600 !important;
      }
    }
  }
}