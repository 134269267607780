.approval-detail-screen {
  .center-vh {
    transform: translateY(50%);
    height: 50%;
    text-align: center;
  }


  .approval-detail-section {
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
  
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  
    .approval-detail-section-title {
      padding: 20px;
      border-bottom: 1px solid #e0e0e0;
      background-color: #fff;
    }
  
    .approval-detail-content {
      padding: 20px;
      background-color: #fff;
      font-size: 14px;
  
      .approval-detail-row {
        margin-bottom: 16px;
        .approval-detail-row-title {
          color: #9e9e9e;
        }
      }

      .badge {
        --padding-bottom: 10px;
        --padding-end: 20px;
        --padding-start: 20px;
        --padding-top: 10px;
        border-radius: 25px;


        &.approved {
          background: #27A397;
        }

        &.rejected {
          background: #F23D3D;
        }
      }
    }
  }

  .approval-detail-content {
    .approval-section {
      box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    
      .approval-content {
        padding: 20px;
        background-color: #fff;
        font-size: 14px;
    
        .approval-row {
          margin-bottom: 16px;
          .approval-row-title {
            color: #9e9e9e;
          }
        }
      }
    }
  }
}

.modal-approval .modal-wrapper {
  top: 25%;
  position: absolute;
  display: block;
  border-radius: 25px;
  height: 50%;

  @media screen and (max-width: 600px) {
    height: 40%;
  }
}