.create-issue {
  .cr-issue-section {
    background-color: #fff;
    padding: 15px 20px;
    margin-bottom: 15px;

    &.cr-issue-step {
      display: inline-flex;
      width: 100%;
      justify-content: center;
      align-items: flex-end;
      
      .cr-issue-step-item {
        text-align: right;
        color: #595959;
        font-weight: 600;
        font-size: 13px;

        &.active {
          color: #fe6600;
        }
      }
    }

    .cr-issue-form {
      font-size: 14px;

      .cr-issue-form-item {
        margin-bottom: 20px;

        .cr-issue-select {
          padding: 10px 0 5px;
          border-bottom: 1px solid #e0e0e0;
          background: url('../../../../assets/new-icons/downsmall.svg') no-repeat right;
        }

        .native-input, .native-textarea {
          // opacity: 1;
          padding: 10px 0 5px;
          border-bottom: 1px solid #e0e0e0;
          --placeholder-color: #bdbdbd;
          --placeholder-opacity: 1;
        }

        .native-input[disabled] {
          opacity: 0.5;
        }

        .native-textarea {
          height: 80px;
        }

        .cr-issue-checkbox {
          --border-color: #fe6600;
          --border-width: 1px;
          --background-checked: #fe6600;
          --size: 16px;
          float: right;
        }

        &.cr-issue-form-date {
          img {
            filter: brightness(0.8);
            position: relative;
            top: 5px;
          }

          .filter-date {
            border: none;
            padding: 10px 10px 0;
            width: 100%;
            outline: 0 !important;
          }
        }
      }
    }

    .cr-issue-add-package {
      text-align: center;
      padding: 10px;
      border: 1px dashed #fe6600;
      color: #fe6600;
      border-radius: 8px;
      cursor: pointer;
      margin-bottom: 20px;

      img {
        margin-right: 10px;
        filter: invert(50%) sepia(100%) saturate(308%) hue-rotate(125deg) brightness(88%) contrast(92%);
        vertical-align: bottom;
      }
    }
  }

  .cr-issue-footer {
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    margin-bottom: 0;

    .cr-issue-btn {
      --box-shadow: none;
      text-transform: capitalize;

      &.ion-color-primary {
        --ion-color-base: #fe6600 !important;
        --ion-color-tint: #fe6600 !important;
        --ion-color-shade: #fe6600 !important;
      }
    }
  }

  .center-vh {
    transform: translateY(50%);
    height: 50%;
    text-align: center;
  }
}