.admin-card {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.23);
  margin-top: 15px;
  color: #000;
  
  .admin-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .admin-phone {
    margin-bottom: 5px;
  }
}