.approval-screen {
  .center-vh {
    transform: translateY(50%);
    height: 50%;
    text-align: center;
  }

  .history-approval-header {
    float: right;

    img {
      margin-left: 20px;
      cursor: pointer;
    }

    .approval-button {
      float: right;
      cursor: pointer;

      .notification-badge {
        background-color: red;
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        border-radius: 100%;
      }
    }
  }

  .approval-content {
    .report-item {
      background-color: #fff;
      padding: 15px;
      border-bottom: 1px solid #e0e0e0;
      line-height: 1.7;
      color: #404040;
      font-size: 12px;
  
      img {
        float: right;
        position: relative;
        top: 27px;
      }
    }

    .badge {
      --padding-bottom: 10px;
      --padding-end: 20px;
      --padding-start: 20px;
      --padding-top: 10px;
      border-radius: 25px;


      &.approved {
        background: #28A94A;
      }

      &.rejected {
        background: #F23D3D;
      }
    }
  }

  ion-fab {
    
    left: calc(50% - 25px);
  }

  ion-fab-button {
    width: 100px;
    --border-radius: 24px;
  }
}