.create-report {
  ion-button.no-padding {
    --padding-start: 0;
    --padding-end: 0;
  }

  ion-label {
    white-space: normal !important;
  }
}
