.profile-screen {
  .ritase-toolbar {
    display: flex;
    flex-direction: row;
  }
  ion-content {
    --padding-top: 22px;
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;

    div {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    
    .avatar {
      // border-radius: 50%;
      width: 50px !important;
    }

    ion-card{
      padding: 10px;
      margin: 0px;
    }

    ion-grid {
      padding: 0px;
      margin: 0px;
    }
    .text-muted {
      color: #9e9e9e;
    }
    .label {
      color: black;
      font-weight: 700;
    }
    .username {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      flex-direction: row;
      width: 100%;
      padding: 10px;

      img {
        width: 30px;
      }
    }
  }
}
