.ritase-toolbar {
  --box-shadow: none;
  .logo {
    text-align: center;
  }
}

.homescreen {
  ion-content {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }
}

.shipper-menu-button {
  padding: none;
  margin: none;
  width: 5px;
}

.trip-history {
  height: 48px;
  width: 48px;
}

.trip-card {
  // padding: 15px 13px 18px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.23);
  margin-top: 15px;
}

.trip-id {
  text-align: right;
}

.card-title {
  font-size: 14px;
  width: 90%;
}

ion-img.truck-icon {
  width: 20px;
  margin-right: 5px;
  display: inline-block;
}

ion-img.error-image {
  width: 30%;
  max-width: 200px;
  margin: 100px auto 20px;
}

.error-description {
  display: flex;
  justify-content: center;
  color: grey;
}