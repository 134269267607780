.ritase-toolbar {
  --box-shadow: none;
  .logo {
    text-align: left;
  }
}

.vehiclechecklistscreen {
  ion-card-content {
    padding: 0;
  }

  ion-item-divider {
    background-color: white;
    padding: 0;
  }

  hr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin: 0;
  }
  
  .color {
    &-black {
      color: black;
    }
  }

  .next-button {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: transparent;
    padding: 7px 10px;
    border-radius: 16px;
    float: right;
  }
}
